import React from "react"
import { Link } from "gatsby"
import NavbarItemIcon from "./navbar-item-icon"

const NavbarItem = ({ to, icon, children, partiallyActive, ...props }) => {
    if (partiallyActive === null || partiallyActive === undefined)
        partiallyActive = true

    const internal = /^\/(?!\/)/.test(to)
    const classNames = 'link block text-center p-1 text-gray-600 h-full hover:text-primary flex flex-col items-center'

    return (
        <li className="flex-1">
            {internal &&
                <Link to={to} className={classNames} activeClassName="text-primary-bright" partiallyActive={partiallyActive} {...props}>
                    <NavbarItemIcon icon={icon}>{children}</NavbarItemIcon>
                </Link>
            }

            {! internal &&
                <a href={to} className={classNames} {...props}>
                    <NavbarItemIcon icon={icon}>{children}</NavbarItemIcon>
                </a>
            }
        </li>
    )
}

export default NavbarItem
