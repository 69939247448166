import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import shareIcon from "../../images/ios-share.svg"

class InstallPrompt extends React.Component {
    state = {
        showInstallPrompt: false
    }

    constructor(props) {
        super(props)
        this.dismiss = this.dismiss.bind(this)
    }

    componentDidMount() {
        if (this.isIPhone()
            && !this.isInStandaloneMode()
            && !window.localStorage.getItem('hide-install-promt')) {

                this.setState({ showInstallPrompt: true })
        }
    }

    isIPhone() {
        if (!window)
            return false

        const userAgent = window.navigator.userAgent.toLowerCase()
        return /(iphone|ipod);/.test(userAgent)
    }

    isInStandaloneMode() {
        if (!window)
            return false

        return ('standalone' in window.navigator) && (window.navigator.standalone)
    }


    dismiss() {
        this.setState({ showInstallPrompt: false })

        try {
            window.localStorage.setItem('hide-install-promt', true)
        } catch(e) {}
    }

    render() {
        if (!this.state.showInstallPrompt)
            return null

        return (
            <StaticQuery
                query={graphql`
                    query {
                        file(relativePath: { eq: "icon.png" }) {
                            childImageSharp {
                                fixed(width: 32, height: 32) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div className="fixed rounded text-sm z-100 bg-gray-200 mb-16 bottom-xs inset-x-xs triangle-bottom text-gray-200 p-3 flex items-center fade-in-up">
                        {data.file && data.file.childImageSharp &&
                        <span className="bg-black rounded-md inline-block overflow-hidden leading-none mr-3 flex-none">
                            <Img fixed={data.file.childImageSharp.fixed} />
                        </span>
                        }
                        <div className="text-gray-900 text-xs leading-snug">
                            Die <strong>Goldfieber</strong> Web-App installieren: <img src={shareIcon} width={14} className="inline -mt-1" alt="Share Icon" /> tippen und dann <strong>Zum Home-Bildschirm</strong> wählen.
                        </div>
                        <button onClick={this.dismiss} className="w-12 ml-1 -mr-1 text-center text-gray-600 text-sm self-stretch" aria-label="Installatioshinweis verbergen">
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </button>
                    </div>
                )}
            />
        )
    }
}

export default InstallPrompt
