import React from "react"
import PropTypes from "prop-types"
import Navbar from "./navbar"
import InstallPrompt from "./install-prompt"
import { library } from '@fortawesome/fontawesome-svg-core';
import "../../styles/app.css"

import {
    faSearch,
    faStore,
    faCalendar,
    faHome,
    faHomeLg,
    faInfoCircle,
    faAngleRight,
    faStar,
    faLocation,
    faArrowLeft,
    faWallet,
    faMapMarker,
    faEnvelope,
    faGlobe,
    faLink,
    faPhone,
    faClock,
    faSpinner,
    faTimes,
    faSurprise,
    faExternalLink,
    faMap,
} from '@fortawesome/pro-light-svg-icons'

import {
    faInstagram,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faSearch,
    faStore,
    faCalendar,
    faExternalLink,
    faHome,
    faHomeLg,
    faInfoCircle,
    faAngleRight,
    faStar,
    faLocation,
    faArrowLeft,
    faWallet,
    faMapMarker,
    faEnvelope,
    faLink,
    faGlobe,
    faPhone,
    faClock,
    faSpinner,
    faTimes,
    faSurprise,
    faMap,
    faInstagram
)

const Layout = ({ children, mainClassName, mainClassNameAppend }) => {
    if (!mainClassName) {
        mainClassName = "min-h-screen bg-white pb-24"
    }

    if (mainClassNameAppend) {
        mainClassName = [mainClassName, mainClassNameAppend].join(" ")
    }

    return (
        <>
            <Navbar />
            <main className={mainClassName}>
                {children}
            </main>
            <InstallPrompt />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
