import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NavbarItemIcon = ({ icon, children }) => {
    return (
        <>
            <span className="block flex-1 w-6 mx-auto text-xl opacity-90 flex items-center justify-center">
                <FontAwesomeIcon icon={Array.isArray(icon) ? icon : ["fal", icon]} />
            </span>
            <span className="font-bold text-xs block py-1">{children}</span>
        </>
    )
}

export default NavbarItemIcon
