import React from "react"
import NavbarItem from "./navbar-item"

const Navbar = () => {
    return (
        <nav className="bg-black fixed inset-x-0 bottom-0 z-50">
            <ul className="flex items-stretch min-h-16">
                <NavbarItem to="/" icon="star" partiallyActive={false}>Heute</NavbarItem>
                <NavbarItem to="/events" icon="calendar">Kalender</NavbarItem>
                <NavbarItem to="/stores" icon="store">Geschäfte</NavbarItem>
                <NavbarItem to="/about" icon="info-circle">Info</NavbarItem>
                <NavbarItem to="https://www.instagram.com/nagoldfieber/" target="_blank" rel="noopener noreferrer" icon={['fab', 'instagram']}>Instagram</NavbarItem>
            </ul>
        </nav>
    )
}

export default Navbar
